import { debounce, isEmpty } from 'lodash';
import { ErrorMessage } from '@hookform/error-message';
import { Fragment } from 'react';
import { mergeSerials } from 'utils/helpers';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import Modal from 'components/common/DEPRECATED/Modal';
import NumberInput from './NumberInput';
import React, { useEffect, useState } from 'react';
import ReactTable from 'components/common/ReactTable';
import styled from 'styled-components';
import useDisabledItems from 'utils/useDisabledItems';

const UpdateSerialModal = ({
  isOrder,
  data: _data,
  draftItems = [],
  onSubmit,
  onDiscard,
  onToggle,
  title,
  actions,
  submitText,
  remaining,
  items,
  methods,
  isFetchingData,
  authStore,
}) => {
  const [tableKey, setTableKey] = React.useState(1);
  const [error, setError] = useState();
  const [form, setForm] = useState({ items: [] });
  const [data, setData] = useState([]);
  const ref = React.useRef();
  const { register, setValue, errors } = methods;
  const [qtyInput, setQtyInput] = React.useState({});
  const { filterDisabled, selectedDisabled, onChangeDisabled } = useDisabledItems();
  const currentWarehouse = window.location.pathname.split('/')[2];

  useEffect(() => {
    setData(_data);
    filterDisabled(_data);
  }, [_data, filterDisabled]);

  const onSave = () => {
    const quantities = data
      .filter((i) => i.quantity)
      .map(({ sku, quantity }) => {
        return { sku, quantity };
      });

    onSubmit([...form.items, ...quantities])
      .then(() => onToggle())
      .catch((err) => {
        setError(err.message);
      });
  };

  const handleSelectionChange = React.useCallback(
    (selected) => {
      const items = mergeSerials(selected);
      if (!isEqual(items, form.items)) {
        setForm((prevState) => ({ ...prevState, items }));
      }
    },
    [form.items]
  );

  const handleDiscard = async () => {
    await onDiscard();
    setTableKey((prevState) => prevState + 1);
  };

  const handleGlobalFilter = (e) => {
    if (ref) {
      ref.current.setGlobalFilter(e.target.value);
    }
  };

  const quantityRef = React.useRef(
    debounce((value, item) => {
      onChangeDisabled(value, item);
    }, 500)
  );

  const columns = React.useMemo(() => {
    const handleChange = (value, item) => {
      item.quantity = value;
      quantityRef.current(value, item);
    };
    return [
      {
        Header: 'Serial No.',
        accessor: 'sn',
        width: 20,
      },
      {
        Header: 'Product Code',
        accessor: 'sku',
        width: 20,
      },
      ...(authStore.isSupportRefurbished(currentWarehouse)
        ? [
            {
              Header: 'Type',
              accessor: 'type',
              width: 10,
            },
          ]
        : []),
      {
        Header: isOrder ? 'Assign Quantity' : 'Received Quantity',
        className: 'text-center',
        width: 20,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          if (!original.sn) {
            const found = items.find((i) => i.sku === original.sku);
            const received = found.confirmedQuantity || found.receivedQuantity;

            const name = `${original.sku}`;
            setQtyInput((prevState) => ({ ...prevState, [name]: name }));

            return (
              <NumberInput
                name={name}
                ref={register({
                  min: {
                    value: 0,
                    message: `${name} is required`,
                  },
                  max: {
                    value: found.quantity - received,
                    message: `${name} is invalid`,
                  },
                })}
                defaultValue={original.quantity}
                className="m-auto confirm-qty-box form-control"
                onChange={(e) => {
                  const {
                    target: { value, name },
                  } = e;
                  setValue(name, value, { shouldValidate: true });
                  handleChange(value, original);
                }}
              />
            );
          }
          return null;
        },
      },
    ];
  }, [isOrder, items, register, setValue, authStore, currentWarehouse]);

  const selectedRows = React.useMemo(() => {
    const selectedItems = form.items.reduce((acc, value) => {
      return acc + value.quantity;
    }, 0);
    return selectedItems + selectedDisabled.length;
  }, [form.items, selectedDisabled]);

  return (
    <Modal
      size="lg"
      title={title}
      isOpen={true}
      onToggle={onToggle}
      onSubmit={onSave}
      submitText={submitText}
      actions={() => (actions ? actions(form, setError, handleDiscard, data) : null)}
    >
      {error && !!error.length && (
        <p className="alert alert-danger">
          {error.map((i, key) => (
            <Fragment key={key}>
              {i}
              <br />
            </Fragment>
          ))}
        </p>
      )}
      {!isEmpty(errors) && (
        <div className="alert alert-danger">
          <ListStyled>
            {Object.keys(qtyInput).map((name, key) => (
              <ErrorMessage key={key + name} errors={errors} name={name}>
                {({ messages }) => {
                  return messages && Object.entries(messages).map(([type, message]) => <li key={type}>{message}</li>);
                }}
              </ErrorMessage>
            ))}
          </ListStyled>
        </div>
      )}
      {selectedRows > remaining && <p className="alert alert-danger">Invalid Quantity</p>}

      <input type="text" className="form-control border-0" placeholder="Search" onChange={handleGlobalFilter} />
      <ReactTableStyled>
        <ReactTable
          ref={ref}
          data={_data}
          columns={columns}
          key={tableKey}
          options={{
            isLoading: isFetchingData,
            isMulti: true,
            selectKeyField: 'sn',
            className: 'mb-1',
            disabledProp: 'disabled',
            onSelectionChanged: handleSelectionChange,
            defaultSelected: draftItems,
            defaultPageSize: 15,
          }}
        />
      </ReactTableStyled>

      <div
        id="selected-qty"
        className={cx({ 'text-danger': selectedRows > remaining })}
      >{`Selected Qty: ${selectedRows}`}</div>
    </Modal>
  );
};

export default UpdateSerialModal;

const ReactTableStyled = styled.div`
  .table-responsive {
    max-height: calc(95vh - 180px);
    .table-footer {
      position: sticky;
      bottom: 0;
      background: #fff;
      z-index: 1;
    }
    .gtvvZR {
      z-index: 2;
    }
  }
`;

const ListStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
