import { debounce, isEmpty } from 'lodash';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { getDisabled, getSerialItems, mergeSerials } from 'utils/helpers';
import { inject, observer } from 'mobx-react';
import { toast } from 'components/common/ReactToast';
import { useForm } from 'react-hook-form';
import Checkbox from 'components/common/Checkbox';
import confirm from 'components/common/ConfirmManager';
import ConfirmDialog from 'components/common/ConfirmDialog';
import CreateShipmentModal from 'components/Orders/CreateShipmentModal';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import NumberInput from 'components/common/NumberInput';
import React from 'react';
import ReactSelect from 'components/common/ReactSelect';
import ReactTable from 'components/common/ReactTable';
import styled from 'styled-components';
import useDisabledItems from 'utils/useDisabledItems';
import useToggleModal from 'utils/useToggleModal';

const getInitialFormState = () => ({
  selectedOrderNumber: { value: '' },
  sns: [],
  snsText: [],
  isManualInput: false,
  carrier: '',
  waybill: '',
  errors: [],
});

const getInitialPageState = () => ({
  order: { lines: [] },
  selected: [],
  items: [],
});

const StockFulfillment = (props) => {
  const { orderStore, serialNumberStore, stockStore, inventoryStore, history, authStore } = props;
  const [form, setForm] = React.useState(getInitialFormState());
  const [page, setPage] = React.useState(getInitialPageState());
  const [orderOptions, setOrderOptions] = React.useState();
  const [serials, setSerials] = React.useState();
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [draftItems, setDraftItems] = React.useState([]);
  const [disabled, setDisabled] = React.useState([]);
  const [qtyInput, setQtyInput] = React.useState({});
  const methods = useForm({
    criteriaMode: 'all',
  });
  const { register, handleSubmit, setValue, errors } = methods;
  const { selectedDisabled, filterDisabled, onChangeDisabled } = useDisabledItems();
  const currentWarehouse = window.location.pathname.split('/')[2];

  const { toggleModal } = useToggleModal({
    createShipmentModal: false,
  });

  const debounceRef = React.useRef(
    debounce((newValue) => {
      const snsText = newValue.split('\n').map((i) => i.trim());
      setForm((prevState) => ({ ...prevState, snsText }));
    }, 500)
  );

  React.useEffect(() => {
    if (serials) {
      debounceRef.current(serials);
    }
  }, [serials]);

  React.useEffect(() => {
    orderStore
      .search({
        page_size: 999,
        page_num: 0,
        status: 'SHIPMENT_TO_FULFIL',
        sorting: {
          sort_by: 'order_ref_id',
          order_by: 'asc',
        },
      })
      .then((res) => {
        setOrderOptions(
          res.items.map((i) => ({ label: i.order_ref_id + ' (' + i.id.substr(0, 8).toUpperCase() + ')', value: i.id }))
        );
      })
      .catch((err) => {
        if (
          err.data.errors === 'Invalid path name' ||
          err.data.errors === 'You are not allowed to access this resource'
        )
          history.push('/notFound');
      });
  }, [orderStore, history]);

  React.useEffect(() => {
    if (page.order.id) {
      setIsLoading(true);
      const skus = page.order.lines.reduce((acc, value) => [...acc, value.sku], []);
      stockStore
        .search({ skus, orderId: form.selectedOrderNumber.label })
        .then((res) => {
          const disabled = getDisabled(page.order).filter((i) => res.items.find((item) => i.sku === item.sku));
          const items = [...disabled, ...getSerialItems(res.items, 'available_sns')];
          const draftItems = getSerialItems((page.order.shipment_draft && page.order.shipment_draft.items) || []);

          setDraftItems(
            draftItems.reduce(
              (acc, value) => ({
                ...acc,
                [items.findIndex((item) => value.sn === item.sn && value.sku === item.sku)]: true,
              }),
              {}
            )
          );
          setPage((prevState) => ({ ...prevState, items }));

          setIsLoading(false);
        })
        .catch((err) => {
          if (
            err.data.errors === 'Invalid path name' ||
            err.data.errors === 'You are not allowed to access this resource'
          )
            history.push('/notFound');
        });
    }
  }, [page.order, stockStore, history, form.selectedOrderNumber]);

  React.useEffect(() => {
    if (page.order.id) {
      if (form.isManualInput && form.snsText.length) {
        setIsLoading(true);
        inventoryStore
          .sns({
            page_size: 999,
            page_num: 0,
            sns: form.snsText,
          })
          .then((res) => {
            if (form.isManualInput) {
              const skuMap = page.order.lines.reduce((acc, value) => [...acc, value.sku], []);
              const serials = res.items.filter((i) => skuMap.indexOf(i.sku) !== -1);
              const items = [...disabled, ...serials];
              setPage((prevState) => ({ ...prevState, items }));
              setIsLoading(false);
            }
          });
      }
    }
  }, [page.order, form.snsText, form.isManualInput, serialNumberStore, disabled, inventoryStore]);

  React.useEffect(() => {
    setData(page.items);
    filterDisabled(page.items);
  }, [filterDisabled, page.items]);

  const getOrder = React.useCallback(
    function(id) {
      setIsLoading(true);
      orderStore.find(id).then((res) => {
        setDisabled(res.lines.filter((i) => !i.requiring_sn).map((i) => ({ ...i, quantity: 0, disabled: true })));
        const items =
          res.shipment_draft &&
          res.shipment_draft.items.reduce((acc, value) => {
            return [...acc, ...value.sns];
          }, []);
        setForm((prevState) => ({
          ...prevState,
          errors: [],
          sns: items || [],
        }));
        setPage((prevState) => ({ ...prevState, order: res }));
      });
    },
    [orderStore]
  );

  React.useEffect(() => {
    if (form.selectedOrderNumber.value) {
      getOrder(form.selectedOrderNumber.value);
    }
  }, [orderStore, form.selectedOrderNumber, getOrder]);
  const handleChangeOrderNumber = (selected) => {
    setSerials('');
    setForm((prevState) => ({ ...getInitialFormState(), isNext: prevState.isNext, selectedOrderNumber: selected }));
  };

  const handleSaveCurrentStatus = (items) => {
    return new Promise((resolve, reject) => {
      orderStore
        .updateOrderShipmentShipmentDraftRequest({
          id: page.order.id,
          items,
        })

        .then(() => {
          orderStore.find(page.order.id, true);
          resolve();
        })
        .catch((err) => {
          reject(err.data.errors);
        });
    });
  };
  const handleSaveCurrentStatusBulk = () => {
    const quantities = page.items
      .filter((i) => i.quantity)
      .map(({ sku, quantity }) => {
        return { sku, quantity, sns: [] };
      });
    const items = mergeSerials(page.selected);
    handleSaveCurrentStatus([...items, ...quantities])
      .then(() => {
        toggleModal('confirmReceiptModal');
        handleChangeOrderNumber(form.selectedOrderNumber);
      })
      .catch((err) => {
        setForm((prevState) => ({ ...prevState, errors: err.message }));
      });
  };

  const onSubmit = ({ waybill, carrier }) => {
    return new Promise((resolve, reject) => {
      const items = mergeSerials(page.selected);
      const disabled = page.items
        .filter((i) => i.quantity)
        .map(({ sku, quantity }) => {
          return { sku, quantity, sns: [] };
        });

      orderStore
        .update({
          id: page.order.id,
          waybill,
          carrier,
          delivery_date: moment().format(),
          items: [...items, ...disabled],
        })
        .then(() => {
          toast.notify({
            type: 'info',
            value: `Products have been assigned to #${page.order.id.substring(0, 8).toUpperCase()}.`,
          });
          resolve();
          setSerials('');
          setForm(getInitialFormState());
        })
        .catch((err) => {
          reject(err.data.errors);
        });
    });
  };

  const handleDiscard = () => {
    confirm.show({
      props: {
        message: 'The entered data will be discard.',
        onConfirm: () => {
          setForm((prevState) => ({ ...prevState, isManualInput: false }));
          getOrder(form.selectedOrderNumber.value);

          return Promise.resolve();
        },
      },
      Component: ConfirmDialog,
    });
  };

  const selectedRows = React.useMemo(() => {
    return page.selected.length + selectedDisabled.length;
  }, [page.selected, selectedDisabled]);

  const handleSelectionChange = React.useCallback(
    (selected) => {
      if (!isEqual(selected, page.selected)) {
        setPage((prevState) => ({ ...prevState, selected }));
      }
    },
    [page.selected]
  );

  const quantityRef = React.useRef(
    debounce((value, item) => {
      onChangeDisabled(value, item);
    }, 500)
  );

  const columns = React.useMemo(() => {
    const handleChange = (value, item) => {
      item.quantity = value;
      quantityRef.current(value, item);
    };

    return [
      {
        Header: 'Serial No.',
        accessor: 'sn',
      },
      {
        Header: 'Product Code',
        accessor: 'sku',
      },
      ...(authStore.isSupportRefurbished(currentWarehouse)
        ? [
            {
              Header: 'Type',
              accessor: 'type',
              width: 10,
            },
          ]
        : []),
      {
        Header: 'Assign Quantity',
        className: 'text-center',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          if (!original.sn) {
            const found = page.order.lines.find((i) => i.sku === original.sku);
            const name = `${original.sku}`;

            setQtyInput((prevState) => ({ ...prevState, [name]: name }));
            return (
              <NumberInput
                name={name}
                ref={register({
                  min: {
                    value: 0,
                    message: `${name} is required`,
                  },
                  max: {
                    value: found.quantity,
                    message: `${name} is invalid`,
                  },
                })}
                defaultValue={original.quantity}
                className="m-auto confirm-qty-box form-control"
                onChange={(e) => {
                  const {
                    target: { value, name },
                  } = e;
                  setValue(name, value, { shouldValidate: true });
                  handleChange(value, original);
                }}
              />
            );
          }
          return null;
        },
      },
    ];
  }, [authStore, currentWarehouse, page.order.lines, register, setValue]);
  const canNext = form.selectedOrderNumber.value;
  return (
    <>
      <div className="container-fluid p-md-5 p-3">
        <div className="breadcrumb">
          <h6>Home {'>'} Stock Fulfillment</h6>
        </div>
        <div className="head-wrapper">
          <div className="row">
            <div className="head-title col-12">
              <h4>Stock Fulfillment</h4>
            </div>
          </div>
        </div>

        {!!form.errors.length && (
          <div className="alert alert-danger">
            <ListStyled>
              {form.errors.map((i, key) => (
                <li key={key}>{i}</li>
              ))}
            </ListStyled>
          </div>
        )}

        {!isEmpty(errors) && (
          <div className="alert alert-danger">
            <ListStyled>
              {Object.keys(qtyInput).map((name, key) => (
                <ErrorMessage key={key + name} errors={errors} name={name}>
                  {({ messages }) => {
                    return messages && Object.entries(messages).map(([type, message]) => <li key={type}>{message}</li>);
                  }}
                </ErrorMessage>
              ))}
            </ListStyled>
          </div>
        )}

        <div className="tracking mt-3">
          <div className="row">
            <div className="panel tracking-panel col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="card shadow-sm">
                <div className="pt-3 px-4 pb-4">
                  <div className="title">Fulfillment Information</div>
                  <div className="form-group pt-2">
                    <label htmlFor="order-number" className="mb-0">
                      Order No.
                    </label>
                    <ReactSelect
                      name="orderNumber"
                      value={form.selectedOrderNumber}
                      onChange={handleChangeOrderNumber}
                      options={orderOptions}
                    />
                  </div>
                  <div className="form-group" style={{ position: 'relative', zIndex: 0 }}>
                    <Checkbox
                      name="isManualInput"
                      checked={form.isManualInput}
                      onChange={(e) => {
                        e.persist();
                        const { checked } = e.target;
                        setForm((prevState) => ({ ...prevState, isManualInput: checked }));
                      }}
                    >
                      Manual Input / Barcode Scanning
                    </Checkbox>
                  </div>
                  {form.isManualInput && (
                    <div className="form-group">
                      <label>
                        Serial No. <small>(one per line)</small>
                      </label>
                      <textarea
                        className="form-control serial-number mt-1"
                        id="sn-box"
                        rows="10"
                        name="sns"
                        onChange={(e) => setSerials(e.target.value)}
                        value={serials}
                      ></textarea>
                    </div>
                  )}
                  <div className="confirm-action d-flex justify-content-end">
                    {!form.isNext && (
                      <button
                        onClick={() => setForm((prevState) => ({ ...prevState, isNext: true }))}
                        className="btn btn-primary btn-next"
                        disabled={!canNext}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {form.isNext && (
              <div className="panel matching-result col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mt-xl-0 mt-lg-0 mt-md-4 mt-sm-4 mt-4">
                <div className="card shadow-sm">
                  <div className="pt-3 px-4 pb-4">
                    <div className="title mb-3">
                      ORDER #{page.order.id && page.order.id.substring(0, 8).toUpperCase()}
                    </div>

                    <ReactTable
                      data={data}
                      columns={columns}
                      key={draftItems}
                      options={{
                        selectKeyField: 'sn',
                        disabledProp: 'disabled',
                        isLoading,
                        onSelectionChanged: handleSelectionChange,
                        isMulti: true,
                        defaultSelected: draftItems,
                      }}
                    />

                    <div id="selected-qty">{`Selected Qty: ${selectedRows}`} </div>
                  </div>
                </div>

                <div className="confirm-action d-flex justify-content-end mt-5">
                  <button className="btn btn-dark btn-discard mr-3" onClick={handleDiscard}>
                    Reset
                  </button>
                  <UncontrolledDropdown direction="up">
                    <DropdownToggle nav className="p-0">
                      <button className="btn btn-primary dropdown-toggle" disabled={!selectedRows}>
                        Assign Stocks
                      </button>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        onClick={handleSubmit(() => {
                          confirm.show({
                            props: {
                              onSubmit: onSubmit,
                              data: page.order,
                            },
                            Component: CreateShipmentModal,
                          });
                        })}
                      >
                        Create Shipment
                      </DropdownItem>
                      <DropdownItem onClick={handleSubmit(handleSaveCurrentStatusBulk)}>
                        Save Current Status
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default inject(({ serialNumberStore, inventoryStore, orderStore, stockStore, authStore }) => ({
  serialNumberStore,
  inventoryStore,
  orderStore,
  stockStore,
  authStore,
}))(observer(StockFulfillment));

const ListStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
