import { action, makeObservable, observable } from 'mobx';

class AuthStore {
  user = {};
  apiFeatures = null;
  accessibleWarehouses = null;

  constructor(props) {
    makeObservable(this, {
      user: observable,
      apiFeatures: observable,
      accessibleWarehouses: observable,
      profile: action,
      isCreateReceivingAccessible: action,
      isSupportRefurbished: action,
    });

    this.api = props.api;
    this.apiFeatures = props.apiFeatures;
    this.accessibleWarehouses = props.accessibleWarehouses;
  }

  getToken = () => {
    return this.api.getToken();
  };
  setToken = (token) => {
    return this.api.setToken(token);
  };
  isCreateReceivingAccessible = (pathName) => {
    switch (pathName) {
      case 'mdg':
        return this.apiFeatures?.includes('CREATE_RECEIVING_ACCESS_MDG') ? true : false;
      case 'west-network':
        return this.apiFeatures?.includes('CREATE_RECEIVING_ACCESS_WEST_NETWORK') ? true : false;
      case 'frontier-us':
        return this.apiFeatures?.includes('CREATE_RECEIVING_ACCESS_FRONTIER_US') ? true : false;
      case 'peplink-lt':
        return this.apiFeatures?.includes('CREATE_RECEIVING_ACCESS_PEPLINK_LT') ? true : false;
      default:
        return false;
    }
  };
  isSupportRefurbished = (pathName) => {
    if (
      this.accessibleWarehouses
        ?.find((wh) => wh.name === this.getWarehouseName(pathName))
        ?.types?.includes('REFURBISHED')
    ) {
      return true;
    } else {
      return false;
    }
  };
  getPathName = (warehouseName) => {
    switch (warehouseName) {
      case 'MDG':
        return 'mdg';
      case 'West Network':
        return 'west-network';
      case 'Frontier US':
        return 'frontier-us';
      case 'Peplink LT':
        return 'peplink-lt';
    }
    return warehouseName;
  };
  getWarehouseName = (pathName) => {
    switch (pathName) {
      case 'mdg':
        return 'MDG';
      case 'west-network':
        return 'West Network';
      case 'frontier-us':
        return 'Frontier US';
      case 'peplink-lt':
        return 'Peplink LT';
    }
    return pathName;
  };
  logout = () => {
    return this.api.removeToken();
  };
  login = (form) => {
    return new Promise((resolve, reject) => {
      this.api
        .login(form)
        .then((res) => {
          this.setToken(res.data.access_token);
          resolve(res.data.access_token);
          this.accessibleWarehouses = res.data.accessibleWarehouses;
          this.user = { user_name: res.data.user_name };
          this.apiFeatures = res.data.api_features;
        })
        .catch((err) => reject(err.response));
    });
  };
  profile = () => {
    return new Promise((resolve) => {
      this.api.profile().then((res) => {
        this.user = res;
        resolve(res);
      });
    });
  };
}

export default AuthStore;
