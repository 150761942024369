import { mapProductName } from 'utils/mapper';
import BaseStore from './base';
import stores from './index';
class InventoryStore extends BaseStore {
  constructor(props) {
    super(props);
    this.api = props.api;
  }

  search = (payload) => {
    return new Promise((resolve, reject) => {
      this.Table.isLoading = true;
      this.api
        .search(payload)
        .then((res) => {
          const skus = res.items.reduce((acc, value) => [...acc, value.sku], []);
          stores.productStore
            .search(skus)
            .then((productRes) => {
              this.Table.data = res;
              this.Table.data.items = mapProductName(this.Table.data.items, productRes);
              resolve(productRes);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          this.Table.isLoading = false;
        });
    });
  };

  sns = (payload) => this.api.sns(payload);
  summary = (params) => {
    return this.api.summary(params);
  };
  inventory = (params) => this.api.inventory(params);
}

export default InventoryStore;
