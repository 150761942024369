import BaseServices from './base';
export default class inventoryServices extends BaseServices {
  constructor(props) {
    props.url = '/inventory';
    super(props);
  }
  inventory = (params) => {
    const currentWarehouse = window.location.pathname.split('/')[2];
    const queryParams = new URLSearchParams(params).toString();
    return this.http.get(`${this.url}/${currentWarehouse}?${queryParams}`);
  };

  sns = (payload) => {
    const currentWarehouse = window.location.pathname.split('/')[2];
    return this.http.post(`${this.url}/search/${currentWarehouse}/sns`, payload);
  };

  summary = (params) => {
    const currentWarehouse = window.location.pathname.split('/')[2];
    const queryParams = new URLSearchParams(params).toString();
    return this.http.get(`${this.url}/summary/${currentWarehouse}?${queryParams}`);
  };
}
